<template>
  <div class="container mt-4">
    <img
      src="../../assets/images/cookie-policy-img.webp"
      class="d-block m-auto thumbnail"
      alt="..."
    />
    <div class="heading-03 text-primary-05 mt-4 text-center mb-5">Termeni și condiții</div>

    <p>
      Website-ul – Site-ul https://delicitate.ro („Website-ul”) este proprietatea GRAPIC ARTISAN
      BAKERY SRL, persoană juridică de drept român, cu sediul social în Suceava, România, Str.
      Celulozei 1B, înmatriculată la Oficiul Registrului Comerțului sub nr. J33/442/2018, Cod Unic
      de Înregistrare 39059291, denumită pe parcursul acestor Termeni și Condiții, “Delicitate”.<br />Comercializarea
      Produselor prin intermediul Website-ului – Delicitate este specializată în realizarea și
      vânzarea produselor de cofetărie și panificație. Delicitate utilizează în procesul de
      fabricație materie primă, iar produsele oferite clienților săi sunt realizate la standarde
      ridicate de calitate. Delicitate vinde produse de cofetărie și panificație realizate atât prin
      intermediul Website-ului, precum și în magazinele sale din țară (lista cu magazinele
      disponibile poate fi accesată aici).<br />Utilizatorul Website-ului este:<br />persoana
      fizică, în vârstă de cel puțin 18 ani, cu capacitate de exercițiu deplină orice reprezentant
      al unei persoane juridice înmatriculate și funcționând în conformitate cu legile din România,
      acționând în numele și pe seama persoanei juridice orice persoană fizică autorizată (PFA);
      care accesează, comandă sau achiziționează produse de cofetărie și panificație prin
      intermediul Website-ului, denumit pe parcursul acestor Termene si Condiții, “Client”.<br />Aria
      de aplicare a Termenelor și CondițiilorTermenele și Condițiile definesc condițiile de
      utilizare a Website-ului și condițiile contractuale ce guvernează relația dintre Delicitate si
      Client. Prin acceptarea Termenelor și Condițiilor, Clientul este de acord că:<br />(i) în
      cazul în care Clientul este o persoană fizică, acesta acționează în nume propriu și nu
      reprezintă nicio altă persoană, iar dacă este reprezentantul unei persoane juridice,
      acționează în limitele împuternicirii ce i-a fost acordată;<br />(ii) Clientul va furniza
      informații corecte și complete în vederea utilizării Website-ului;<br />(iii) Clientul nu va
      utiliza Website-ul pentru a reproduce, duplica, copia, vinde, revinde, distribui, publica sau
      exploata, în niciun scop comercial, software-ul, conținutul, ofertele, produsele sau
      serviciile furnizate de către Delicitate, sau obținute prin intermediul Website-ului fără să
      obțină consimțământul expres, prealabil și scris al Delicitate. Această restricție include
      orice încercare de a încorpora orice informație de pe Website în orice alt catalog, produs sau
      serviciu;<br />(iv) Clientul nu va utiliza Website-ul în niciun mod care ar putea să afecteze
      în mod negativ disponibilitatea resurselor sale sau utilizarea sa de către alți Clienți sau în
      orice mod care ar putea defecta, dezactiva, supraîncărca sau deteriora serverele sau rețelele
      Delicitate sau ale unor terțe persoane; și că Clientul nu va utiliza Website-ul în orice scop
      care este ilegal sau interzis prin aceste Termene și Condiții sau prin alte politici
      relevante.<br />Acceptarea Termenelor și Condițiilor<br />Prin accesarea și utilizarea
      Website-ului, Clientul ia cunoștință de și acceptă prezentele Termene și Condiții, precum și
      celelalte politici relevante pentru Website. Pentru folosirea in bune condiții a Website-ului,
      Clientul are obligația de a citi cu atenție și de a respecta Termenele și Condițiile, precum
      și orice alte politici relevante ale Website-ului, așa cum acestea sunt afișate pe Website sau
      vor fi notificate Clientului la un anumit moment.<br />Modificarea Termenelor și
      Condițiilor<br />Delicitate își rezervă dreptul de a modifica unilateral în orice moment
      Termenele și Condițiile, precum și orice alte politici relevante. Continuarea folosirii
      Website-ului în orice fel, inclusiv prin comandarea si achiziționarea de produse de cofetărie
      și panificație, ulterior modificării Termenelor și Condițiilor reprezintă acceptul Clientului
      cu privire la modificările efectuate. Termenele și Condițiile modificate vor fi aplicate
      oricărei comenzi/tranzacții începând cu ziua modificării acestora. Ultima actualizare a
      Termenelor și Condițiilor a fost efectuată în data de:[10 Aprilie 2021].<br />Accesibilitatea
      Website-ului<br />Website-ul este accesibil clienților 24 de ore din 24, 7 zile din 7 în afara
      cazurilor in care se efectuează operațiuni de mentenanță. Delicitate își rezervă dreptul de a
      întrerupe unilateral accesul la Website (integral sau parțial), în orice moment, cu
      notificarea imediată a utilizatorilor, prin mesaj afișat pe Website la momentul accesării.
      Delicitate nu poate fi ținută răspunzătoare față de Client și/sau față de terțe persoane
      pentru lipsa de funcționalitate sau pentru funcționarea neconformă a Website-ului.<br />UTILIZAREA
      WEBSITE-ULUI<br />2.1 Crearea unui Cont de către Client<br />Pentru efectuarea de cumpărături
      prin intermediul Website-ului, Clientul are posibilitatea de a achiziționa produse de
      cofetărie și panificație fără a avea un cont pe Website, sau poate alege să își creeze un
      astfel de cont, pentru a facilita comenzile viitoare de pe Website. În cazul în care Clientul
      alege să își creeze un cont, aceasta se va realiza urmând instrucțiunile din secțiunea
      ”Creează cont”.<br />Clientul este responsabil pentru menținerea confidențialității datelor
      asociate contului său creat pe Website.<br />Datele solicitate de către Delicitate la momentul
      realizării contului de către Client sunt necesare pentru gestionarea comenzilor și a
      relațiilor comerciale dintre Delicitate si Client.<br />Parola nu va fi folosita pentru
      efectuarea de plăti online, aceasta va fi folosită doar în scopul înregistrării Clientului și
      creării unui Cont în vederea efectuării comenzilor online.<br />Clienții sunt obligați sa
      furnizeze date adevărate, exacte si complete așa cum este indicat și în formularul de
      înregistrare și să reînnoiască, ori de câte ori este necesar, datele de înregistrare pentru a
      fi adevărate, exacte și complete în orice moment.<br />In cazul furnizării de către Client a
      unor informații care nu sunt conforme cu realitatea, inexacte sau incomplete, Delicitate își
      rezervă dreptul de a suspenda sau desființa contul și de a refuza toate încercările curente
      sau viitoare de folosire a serviciilor Website-ului, fără nicio despăgubire față de Client.<br />Aceste
      condiții sunt necesar a fi îndeplinite și în cazul înregistrării participării Clienților la
      concursurile/promoțiile organizate și prezentate în cadrul Website-ului de către Delicitate,
      concursuri/promoții ce vor fi guvernate de regulamente distincte.<br />Clientul este în
      întregime responsabil pentru menținerea securității și confidențialității contului și a
      parolei și este responsabil pentru toate activitățile și conduita sa sau a oricărei alte
      persoane care efectuează comenzi de produse de cofetărie și panificație prin intermediul
      contului.<br />Clientul este de acord să notifice Delicitate imediat cu privire la orice
      suspiciune de utilizare neautorizată a contului sau cu privire la orice suspiciune cu privire
      la o posibilă breșă de securitate. Delicitate sau reprezentanții săi nu vor fi responsabili
      pentru nicio pierdere sau prejudiciu, de orice fel, care ar putea surveni ca urmare a
      utilizării, de către o altă persoană, a contului, cu sau fără permisiunea Clientului. Clientul
      este răspunzător pentru orice pierderi cauzate Delicitate sau oricărei terțe părți ca urmare a
      utilizării Contului de către o altă persoană.<br />În cazul nerespectării de către Client a
      Termenelor și Condițiilor sau a oricăror altor politici relevante, Delicitate își rezervă
      dreptul de a dezactiva contul Clientului, fără nicio despăgubire și fără acordarea unui
      preaviz sau a vreunei alte formalități, după înștiințarea Clientului printr-un e-mail. In caz
      de fraudă sau încălcarea oricăror legi aplicabile din partea Clientului, dezactivarea contului
      se va face fără preaviz, fără înștiințare, fără alte formalități și fără despăgubiri,
      Delicitate procedând exclusiv la informarea Clientului printr-un e-mail.<br />În cazul
      pierderii sau uitării parolei, aceasta poate fi regenerata accesând căsuța „contul meu” și
      introducând adresa de e-mail, urmând ca o parola să fie transmisă prin e-mail. Parola este
      strict confidențială.<br />Clientul care dorește dezactivarea contului său are posibilitatea
      să efectueze această acțiune urmând instrucțiunile din website, secțiunea ”Contul meu” sau
      scriindu-ne pe adresa
      <a href="mailto:salut@delicitate.ro" rel="noopener noreferrer">salut@delicitate.ro</a> Contul
      nu poate fi dezactivat între momentul plasării unei comenzi și livrarea acelei comenzi.<br />În
      situația în care un Client dorește să plaseze o comanda pe Website fără a se înregistra prin
      crearea unui cont, va putea efectua acest lucru prin introducerea datelor solicitate de către
      Website în momentul plasării comenzii.
    </p>

    <p>
      Clientul oferă acordul pentru a primi materiale promoționale, oferte, discount-uri pe adresa
      de e-mail introdusă la plasarea comenzii.
    </p>

    <p>
      2.2 Produse de cofetărie și panificație Delicitate oferite spre comercializare<br />Fiecare
      tip de produs oferit spre vânzare pe Website este însoțit de o descriere a acestuia, permițând
      Clientului sa ia cunoștință de informațiile esențiale cu privire la caracteristicile acestuia
      înainte de a finaliza o comandă.<br />Imaginile folosite pentru descrierea produselor de
      cofetărie și panificație pe Website nu reprezintă o obligație contractuală din partea
      Delicitate, acestea fiind utilizate exclusiv cu titlu de prezentare.<br />Informațiile afișate
      pe Website au doar scop informativ și nu sunt destinate să înlocuiască sfatul unor specialiști
      din diverse domenii (de exemplu, nutriție).<br />2.3 Disponibilitatea produselor de cofetărie
      și panificație și valabilitatea ofertelor<br />Ofertele produselor de cofetărie și panificație
      si prețurile afișate pe Website sunt valabile atât timp cat sunt vizibile pe Website și în
      limita stocului disponibil.<br />Produsele și ofertele valabile vor fi semnalizate pe Website
      prin prezența butonului „Cumpără/Adaugă în coș”, în dreptul acestora.<br />2.4 Prețurile
      produselor de cofetărie și panificație<br />Preturile produselor de cofetărie și panificație
      prezentate pe Website sunt exprimate în RON și au toate taxele incluse, cu excepția costurilor
      de transport.<br />Preturile aplicabile sunt cele afișate pe Website în momentul validării
      comenzii de către Client. In cazul în care produsele din coșul de cumpărături vor suferi
      modificări ale prețurilor, noile prețuri vor fi semnalizate Clientului înainte de finalizarea
      comenzii. Preturile includ TVA , iar toate modificările legale survenite cu privire la
      cuantumul acestei taxe se vor reflecta în prețul produselor de cofetărie și panificație
      prezente pe Website în momentul intrării în vigoare a actului normativ respectiv.<br />2.5
      Plasarea Comenzii și încheierea contractului de vânzare<br />Efectuarea de comenzi de
      produsele de cofetărie și panificație prin intermediul Website-ului nu se poate realiza decât
      pentru adrese din Suceava.<br />În vederea efectuării și înregistrării unei comenzi, Clientul
      trebuie să urmeze instrucțiunile afișate pe Website în acest sens. În momentul dinaintea
      validării comenzii, Clientului i se va pune la dispoziție un recapitulativ al informațiilor
      legate de comandă (număr articole comandate, suma comenzii, tipul de livrare, data livrării și
      intervalul orar ales, precum și adresa selectată pentru livrarea la domiciliu), iar acesta se
      validează printr-un “click” pe butonul “ACCEPT COMANDA” și bifarea căsuței ”Accept că această
      comandă implică o obligație de a plăti”, precum și bifarea căsuței privind acceptare
      prezentelor Termene și Condiții.<br />Comenzile nevalidate prin apăsarea butonului ”ACCEPT
      COMANDA” nu vor fi onorate. Simplul fapt de a adăuga Produse în coșul de cumpărături nu
      echivalează cu validarea comenzii și nu creează nicio obligație pentru Delicitate de a proceda
      la livrarea produselor.<br />Delicitate confirmă înregistrarea comenzii printr-un e-mail
      automat trimis către Client, prin intermediul căruia i se vor comunica următoarele
      informații:<br />lista cu articolele comandate, preturile produselor, tipul de livrare, adresa
      pentru livrarea la domiciliu si intervalul orar ales, precum și factura pentru produsele
      comandate;<br />În acest caz, contractul de vânzare se va considera a fi încheiat la data
      primirii, de către Client, a confirmării înregistrării comenzii.<br />Delicitate poate
      solicita informații suplimentare despre comandă, inclusiv, dar fără limitare, cu privire la
      adresa și ora la care să fie livrată comanda, în cazul în care informațiile furnizate inițial
      de către Client nu sunt suficiente pentru onorarea comenzii. În acest caz, comanda va fi
      validată și contractul de vânzare va fi considerat încheiat doar la data primirii noilor
      informații solicitate. În cazul nefurnizării de către Client a informațiilor suplimentare
      solicitate, contractul de vânzare nu va fi considerat ca fiind valabil încheiat, Delicitate
      neavând obligația de a onora comanda respectivă.<br />Valoarea minimă a comenzii efectuate
      prin intermediul Website-ului și pentru care se solicită transport este de 200 (douasute) lei.
      Pentru a evita orice dubiu, fiecare comandă plasată trebuie să fie de minim 200 (douasute)
      lei.<br />Pentru comenzile efectuate prin intermediul Website-ului, transportul este
      gratuit.<br />Pentru comenzile efectuate prin intermediul Website-ului pentru care Clientul
      optează să le ridice personal din magazinele Delicitate indicate, nu există o valoare minimă
      de comandă.<br />Condițiile pentru plasarea comenzilor sunt următoarele:<br />comenzile se fac
      cu cel puțin o zi înainte.<br />pentru comenzile efectuate în perioadele aglomerate, ca de
      exemplu, dar fără a se limita la zilele de sărbătoare religioase sau legale, zilele cu
      semnificație aparte (cu titlu de exemplu: 1 martie, 8 martie, 1 iunie etc. ) sau în cursul
      weekendului, Clientul va contacta Delicitate prin intermediul e-mailului la adresa
      <a href="mailto:salut@delicitate.ro">salut@delicitate.ro</a> pentru a rezerva produsele
      dorite. În această situație, comanda se va considerată încheiată în momentul transmiterii de
      către Delicitate a confirmării de comandă.<br />Clientul are posibilitatea de a urmări
      statusul comenzilor sale de pe Website, precum și istoricul tranzacțiilor efectuate accesând
      rubrica „Contul meu”.<br />2.6 Plata produselor de cofetărie și panificație<br />Plata
      comenzilor realizate se poate efectua în următoarele modalități:<br />Plata online prin card
      bancar<br />Toate tranzacțiile bancare de pe Website vor fi efectuate într-o manieră
      securizată, procesatorul de plăți fiind garantul acestei securități din punctul de vedere al
      efectuării plăților online și se obligă să protejeze toate datele și informațiile personale ce
      au legătură cu modalitățile de plată online, și să le păstreze, doar dacă este cazul.<br />Suma
      de plată pentru comanda validată va fi blocată de pe cardul valid înregistrat de către Client
      în momentul validării comenzii respective.<br />Suma plătită efectiv pentru comandă va fi
      retrasă doar în momentul în care Clientul intră în posesia produselor de cofetărie și
      panificație comandate și nu poate fi mai mare decât suma blocată inițial în momentul validării
      comenzii.<br />Clientul garantează Delicitate că utilizarea mijlocului de plată ales nu
      încalcă nicio lege aplicabilă. Delicitate își rezervă dreptul de a suspenda/anula orice
      comandă/livrare asupra căreia există suspiciuni cu privire la vreo acțiune ilegală sau
      frauduloasă privind modalitatea de plată.<br />Delicitate își rezervă dreptul de a
      suspenda/anula toate comenzile/livrările în cazul refuzului efectuării plații de către
      instituțiile financiar-bancare (în cazul în care Clientul a ales să efectueze plata produselor
      online), fără nicio despăgubire sau notificare față de Client.<br />Toate informațiile
      introduse în vederea efectuării unei plăți online (numărul cadrului, parola, data validității
      cardului) nu vor fi stocate pe serverele Delicitate, ci vor fi procesate exclusiv de către
      unitățile bancare emitente ale cardurilor.<br />Bonul fiscal va fi eliberat pentru fiecare
      comandă în parte și va însoți produsele livrate. Factura Fiscala aferenta unei comenzi online
      va fi emisă în format electronic si va fi primită și va putea fi descărcată și tipărită după
      cel mult 4 zile de la primirea comenzii.<br />In cazul în care un produs comandat de către
      Client nu poate fi livrat de către din motive independente de voința Delicitate, Clientul va
      fi informat în acest sens și i se va returna în cont contravaloarea produsului, în termen de
      maxim 14 zile de la data la care Delicitate a luat cunoștință de acest fapt.<br />2.7 Livrarea
      produselor de cofetărie și panificație<br />Clientul are posibilitatea de a opta între
      următoarele metode:<br />livrare la adresa indicată de către Client în procesul efectuării
      comenzii;<br />ridicarea produselor de cofetărie și panificație de la punctul de lucru
      Delicitate ales în momentul efectuării comenzii.<br />Clientul se obligă sa fie prezent la
      recepționarea comenzii la adresa indicată în comandă. În cazul în care Clientul nu este
      disponibil să recepționeze personal comanda, acesta va desemna o altă persoană în locul
      său.<br />Clientul are obligația de a verifica corectitudinea informațiilor furnizate cu
      privire la adresa de livrare anterior validării comenzii (strada, numărul străzii,
      blocul/clădirea, numărul, etajul, codul interfonului, codul poștal). În cazul apariției unor
      erori la introducerea acestor informații, Delicitate nu va fi făcută responsabilă pentru
      imposibilitatea livrării comenzii, livrarea cu întârziere a comenzii sau pentru orice alte
      consecințe.<br />Livrarea comenzilor la adresa de livrare indicată de către Client este
      asigurată de către Delicitate, prin reprezentanții săi sau prin partenerii contractuali –
      prestatori de servicii de curierat – denumiți „Curieri”.<br />La solicitarea Delicitate sau a
      Curierului, Clientul va face dovada identității sale, dacă este necesar, prin unul sau mai
      multe acte emise de autorități oficiale și va comunica numărul de comandă atribuit de către
      sistemul de vânzare.<br />În cazul în care Clientul se află în imposibilitatea recepționării
      comenzii în mod personal, comanda va fi livrată la adresa precizată doar unei persoane cu
      vârsta mai mare de 18 ani și doar în cazul comunicării către Curier a numărului comenzii.<br />Nicio
      cerere de livrare a unei comenzi nu va putea fi onorată dacă Clientul nu îndeplinește
      condițiile de mai sus. În cazul livrării la o locație aleasă de către Client, în situația în
      care Curierul nu poate avea acces în locația respectivă, Clientul trebuie să se asigure că
      poate recepționa această comandă.<br />Clientul va fi contactat telefonic de către Curier în
      prealabil pentru a obține confirmarea prezenței acestuia la adresa și ora selectate în
      momentul validării comenzii pe Website.<br />Clientul sau persoana desemnată de acesta se
      obligă să fie prezent la adresa de livrare indicată. În caz contrar, Clientul va suporta
      costul de transport aferent unei posibile noi livrări. Livrarea se consideră a fi îndeplinită
      de către Delicitate la momentul predării produselor comandate către Client la adresa selectată
      de acesta la momentul validării comenzii.<br />Clientul sau persoana desemnată se obligă să
      verifice livrarea, starea produselor de cofetărie și panificație și să semneze documentul de
      livrare prezentat de către Curier la livrarea produselor de cofetărie și panificație
      comandate, la care este anexat și bonul fiscal ce conține toate informațiile despre produsele
      livrate (cum ar fi denumire produs, cantitate, preț).<br />Delicitate se angajează să răspundă
      tuturor cererilor și reclamațiilor in maximum 5 zile lucrătoare de la primirea acestora.<br />În
      ipoteza în care data și ore de livrare selectate de către Client nu vor putea fi respectate,
      Clientul va fi imediat informat prin toate mijloacele puse la dispoziție de către Client,
      respectiv prin intermediul poștei electronice sau SMS.<br />În caz de întârziere a livrării
      Produselor comandate, Delicitate sfătuiește Clientul să anunțe această întârziere contactând
      numărul de telefon
      <a href="tel:0746298695" data-type="tel" data-id="tel:0746298695">0746 298 695</a> pentru a
      putea găsi soluția optimă în vederea livrării produselor.<br />2.8 Dreptul de retragere<br />La
      recepționarea mărfii, Clientul trebuie să se asigure că Produsele livrate corespund din punct
      de vedere calitativ și cantitativ așteptărilor sale. În caz contrar, Clientul poate să
      atenționeze Delicitate apelând numărul de telefon
      <a href="tel:0746298695" data-type="tel" data-id="tel:0746298695">0746 298 695</a> sau prin
      e-mail la adresa: <a href="mailto:salut@delicitate.ro">salut@delicitate.ro</a> în cel mai
      scurt timp pentru a remedia situația creată.<br />Potrivit prevederilor OUG nr. 34/2014,
      dreptul de retragere nu se aplică contractelor având drept obiect furnizarea de produse care
      sunt susceptibile a se deteriora sau a expira rapid. Prin urmare, Clientul nu are dreptul să
      se retragă din Contract, respectiv să returneze sau să refuze să recepționeze un produs
      comandat.<br />2.9 Produse livrate gresit<br />Dacă vi s-a livrat alt Produs decât cel
      comandat, sau dacă vi s-a livrat un produs pe care nu l-ați comandat, vă rugăm să semnalați
      cât mai curând acest lucru apelând numărul de telefon
      <a href="tel:0746298695" data-type="tel" data-id="tel:0746298695">0746 298 695</a> sau prin
      e-mail la adresa: <a href="mailto:salut@delicitate.ro">salut@delicitate.ro</a> pentru a
      returna produsul respectiv și a fi înlocuit cu cel corect, dacă este cazul.<br />Toate
      costurile de retur și transport al produsului, precum și costurile aferente livrării
      produsului comandat (daca este cazul) vor fi suportate de<br />RELATIA CU CLIENTII<br />Pentru
      orice informație sau reclamație referitoare la Website sau la comenzi, Clienții au la
      dispoziție următoarele date de contact:<br />telefonic la numărul apelând numărul de telefon
      <a href="tel:0746298695" data-type="tel" data-id="tel:0746298695">0746 298 695</a> sau prin
      e-mail la adresa: <a href="mailto:salut@delicitate.ro">salut@delicitate.ro</a>.<br />PROTECTIA
      DATELOR CU CARACTER PERSONAL<br />Delicitate colectează, prin intermediul Website-ului datele
      cu caracter personal ale Clientului. Clientul trebuie să consulte Politica de
      Confidențialitate aplicabilă Website-ului la
      <a
        href="/politica-de-confidentialitate"
        data-type="URL"
        data-id="/politica-de-confidentialitate"
        >politica-de-confidentialitate</a
      >.<br />Website-ul utilizează cookies. Clientul trebuie să consulte Politica privind Cookies
      la adresa
      <a
        href="/politica-de-confidentialitate"
        data-type="URL"
        data-id="/politica-de-confidentialitate"
        >politica-de-confidentialitate</a
      >.<br />PROPRIETATE INTELECTUALA<br />Conținutul Website-ului este protejat de legile privind
      drepturile de proprietate intelectuală aplicabile, inclusiv dar fără a se limita la Legea nr.
      8/1996 privind drepturile de autor și drepturile conexe. Toate textele, formatările (inclusiv,
      fără a se limita selectarea, coordonarea și aranjarea materialelor pe Website) și imaginile,
      graficele, animațiile, instrumentele, aplicațiile, muzica, sunetele, articolele, copiile,
      materialele, fotografiile, mărcile, denumirile comerciale și logo-urile, precum și alte
      materiale și informații de pe acest Website sunt protejate de drepturi de proprietate
      intelectuală ale Delicitate, subsidiarilor și afiliaților, și ale licențiatorilor acestora
      (denumite în mod colectiv ”Conținutul”). Aceste materiale nu pot fi copiate, supuse unor
      operațiuni de inginerie inversă, decompilate, dezasamblate, modificate, publicate pe alte
      website-uri, încadrate, schimbate sau distribuite, redistribuite, licențiate, sub-licențiate
      sau transferate în orice formă de către Client. Niciunul dintre elementele de pe Website nu va
      fi considerat a acorda direct, implicit, sau în orice alt mod, orice licență sau drept de a
      utiliza, în orice scop Conținutul, în tot sau în parte. Mărcile, logourile, numele comerciale
      și mărcile, atât înregistrate cât și neînregistrate (denumite, în mod colectiv, ”Mărcile”)
      afișate pe Website sunt Mărci aparținând Delicitate sau terților parteneri afiliați ai
      acestora. Nimic de pe Website nu trebuie interpretat ca acordând, în mod direct, implicit, sau
      în alt mod, orice licență sau drept de utilizare a oricărei Mărci afișate pe Website.<br />Clientul
      declară și înțelege că orice utilizare neautorizată a acestor materiale reprezintă încălcarea
      drepturile de proprietate intelectuală ale Delicitate sau terților parteneri afiliați ai
      acestora și va atrage exercitarea, de către aceștia a tuturor remediilor legale.<br />Delicitate
      oferă Clientului o licență limitată, neexclusivă, revocabilă pentru a vedea, distribui, printa
      sau descărca orice Conținut, așa cum acesta este definit mai sus, de pe Website, pentru uzul
      său personal. Clientului nu îi este acordat dreptul să licențieze, republice, distribuie,
      copieze, cesioneze, sub-licențieze, transfere, vândă, creeze opere derivate sau orice altă
      utilizare non-personală a oricărui Conținut al Website-ului. Nicio parte a Conținutului nu
      poate fi reprodusă în nicio formă sau încorporată în orice sistem electronic sau mecanic de
      găsire a informațiilor, altfel decât în scopul folosirii personale. Clientul nu poate accesa
      sau utiliza Website-ul în niciun fel care ar putea sau care are scopul de a dăuna sau afecta
      Website-ul sau orice server sau rețea care stă la baza Website-ului, sau de a interfera cu
      utilizarea și dreptul oricărei alte persoane sau entități de a utiliza Website-ul.<br />ALTE
      PREVEDERI<br />Website-ul poate conține legături („Link-uri”) sau trimiteri către alte
      website-uri/pagini web pentru utilizarea cărora se vor aplica Termene și Condiții de utilizare
      specifice, astfel cum sunt acestea specificate pe website-urile/paginile respective,
      Delicitate nefiind răspunzătoare și neasumându-și nicio obligație pentru conținutul
      respectivelor website-uri și/sau cu privire la orice alte legături sau trimiteri din acestea
      către alte website-uri sau pagini web.<br />Includerea acestor Link-uri sau trimiteri pe
      Website este realizată, de regulă, pentru ajutorul sau în interesul Clientului, iar în alte
      cazuri în scop publicitar.<br />Delicitate nu garantează/controlează actualitatea/exactitatea
      informațiilor prezente pe website-urile unor terți către care trimit link-urile de pe
      Website.<br />Prin continuarea utilizării Website-ului, Clientul înțelege și acceptă că
      accesează acele website-uri și/sau folosește produsele/serviciile oferite prin intermediul
      acestora, exclusiv pe propriul risc și pe propria răspundere și că nu poate solicita
      Delicitate nu este răspunzător pentru prejudiciile suferite ca urmare a accesării acestor
      link-uri și folosirii serviciilor puse la dispoziției pe website-urile către care Link-urile
      fac trimitere.<br />DESPAGUBIRI<br />Clientul declară și recunoaște că este de acord să
      despăgubească, apere și să exonereze de orice responsabilitate, pierdere, solicitare și
      cheltuiala, Delicitate și partenerii săi contractuali și funcționarii, directorii, angajații
      și afiliații acestora, inclusiv dar fără a se limita la, taxe și costuri rezonabile aferente
      avocaților în legătură cu sau rezultând din: (i) încălcarea acestor Termene și Condiții de
      către Utilizator, sau a oricăror alte politici relevante, în special cele privind
      confidențialitatea; (ii) utilizarea necorespunzătoare a Website-ului, de către Client; (iii)
      nerespectarea sau încălcarea drepturilor de autor sau a altor drepturi de proprietate
      intelectuală sau a altor drepturi ale unor terțe părți prin încălcarea clauzei – Proprietate
      Intelectuală – din acești Termene și Condiții.<br />Delicitate va despăgubi Clientul pentru
      prejudiciul direct creat ca urmare a (i) încălcării acestor Termene și Condiții, (ii)
      acțiunilor culpabile săvârșite cu intenție sau neglijență gravă de către proprii angajați. În
      aceste două cazuri, se vor aplica prioritar remediile prevăzute de prezentele Termene și
      Condiții.<br />FORTA MAJORA<br />Toate evenimentele de forță majoră definite de legislația
      română reprezintă un motiv de suspendare si stingere a obligațiilor Delicitate.<br />Niciuna
      dintre părți nu va fi răspunzătoare pentru neexecutarea obligațiilor sale decurgând din acești
      Termene și Condiții, dacă o astfel de neexecutare la termen și/sau în mod corespunzător, total
      sau parțial este datorată unui eveniment de forță majoră. Forţa majoră este orice eveniment
      extern, imprevizibil, absolut invincibil şi inevitabil.<br />Dacă în termen de 15
      (cincisprezece) zile de la data producerii lui, respectivul eveniment nu încetează, fiecare
      parte va avea dreptul să notifice celeilalte părți anularea comenzii, fără ca vreuna dintre
      ele să poată pretinde celeilalte alte daune-interese.<br />Delicitate nu pot fi trasă la
      răspundere in aceste cazuri de forță majoră.<br />RECLAMATII SI LITIGII<br />Acești Termene și
      Condiții sunt supuse legislației din România. În caz de apariție a unor dispute, Clientul va
      putea sa depună reclamații la adresa de e-mail
      <a href="mailto:salut@delicitate.ro">salut@delicitate.ro</a> pentru a ajunge la o soluționare
      amiabilă. Însă, în condițiile în care nu s-a ajuns la o înțelegere amiabilă între părți,
      litigiile vor fi soluționate de instanțele judecătorești competente.<br />Prin UTILIZAREA
      WEBSITE-ULUI vă exprimați în mod expres acordul cu privire la următoarele prevederi ale
      acestor Termene și condiții: PREVEDERI GENERALE, UTILIZAREA WEBSITE-ULUI, PROPRIETATE
      INTELECTUALĂ, ALTE PREVEDERI, DESPĂGUBIRI, RECLAMAȚII ȘI lITIGII.<br />NOTĂ DE INFORMARE
      PRIVIND PROTECȚIA DATELOR CU CARACTER PERSONAL<br />Conform cerintelor Legii nr. 677/2001
      pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera
      circulatie a<br />acestor date, modificata si completata, ale Regulamentului UE nr. 679/2016
      (Regulamentul General pentru Protectia Datelor) si ale Legii<br />506/2004 privind prelucrarea
      datelor cu caracter personal si protectia vietii private în sectorul comunicatiilor
      electronice, Delicitate are obligatia de a administra datele personale pe care ni le furnizati
      în conditii de siguranta si numai în scopurile specificate mai jos.<br />Acceptul
      dumneavoastra privind utilizarea datelor cu caracter personal de catre Societatea Delicitate
      poate fi revocat oricând revocarea producând efecte numai pentru viitor.<br />Informatiile
      înregistrate sunt destinate utilizarii de catre Delicitate si sunt comunicate numai în vederea
      îndeplinirii obligatiilor<br />legale/fiscale/contractuale ce revin Societatii.<br />Datele cu
      caracter personal comunicate nu vor fi dezvaluite tertilor în vederea transmiterii de catre
      acestia de oferte, mesaje publicitare sau<br />reclame. Datele dumneavoastra nu vor fi
      transferate în afara teritoriului României. Prelucrarea datelor cu caracter personal
      solicitate este<br />necesara în vederea înregistrarii comenzilor si livrarii comenzilor,
      respectiv emiterii facturilor pe numele dvs, iar refuzul furnizarii acestor date<br />ne poate
      pune în imposibilitatea de a înregistra comanda dvs, de a va livra produsele si de a va emite
      facturile.<br />În legatura cu prelucrarea datelor cu caracter personal si în baza conditiilor
      specificate de legea nr. 677/2001 si în Regulamentul privind<br />protectia datelor la nivel
      European 679/2016, va puteti exercita oricare dintre urmatoarele drepturi:<br />– dreptul de
      acces la datele personale care va privesc;<br />– dreptul de a solicita rectificarea sau
      actualizarea atunci când datele sunt inexacte sau incomplete;<br />– dreptul de a solicita
      stergerea datelor în anumite circumstante (de ex. când datele personale nu mai sunt<br />necesare
      scopurilor mentionate mai sus);<br />– dreptul de a solicita restrictionarea prelucrarii în
      anumite circumstante;<br />– dreptul de a depune o plângere la noi, instantei de judecata
      si/sau la autoritatea competenta privind protectia datelor;<br />– dreptul de a va opune
      prelucrarii;
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditionsView',
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
  .thumbnail {
    width: 100%;
  }
}
</style>
